<template>
	<div>
		<div class="h-handle-bg">
			<div class="h-handle-button">
				<p class="h-return">
					<el-button
						type="text"
						size="small"
						@click="back"
						>返回</el-button>
				</p>
				<div class="h-b">
					<el-button
						type="primary"
						size="small"
						@click="add"
						v-right-code="'Tenantmiddleware:Save'"
						>新增</el-button
					>
				</div>
				<div class="h-b">
					<el-button
						type="text"
						size="small"
						@click="save"
						v-right-code="'Tenantmiddleware:Save'"
						>保存</el-button
					>
				</div>
			</div>
		</div>

		<div class="form-list">
			<el-form
				:model="dataSource"
				ref="basicForm"
				:rules="baseCheckRule"
			>
						<el-form-item>
                            <el-col :span="3" class="form-title"><span style="color: red">*</span> 租户系统：</el-col>
							<el-col :span="9">
								<el-form-item prop="TenantSystemName">
                                    <LookPopup v-model="dataSource.TenantSystemName"
                                            dataTitle="租户系统"
                                            dataUrl='omsapi/tenantsystem/getlist'
                                            syncField='TenantSystemName'
                                            @syncFieldEvent="syncMiddlewareSystem"
                                        ></LookPopup>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title" ><span style="color: red">*</span> 中间件服务器：</el-col>
							<el-col :span="9">
								<el-form-item prop="MiddlewareServerName">
                                    <LookPopup v-model="dataSource.MiddlewareServerName"
                                        dataTitle="中间件服务器"
                                        dataUrl='omsapi/tenantserver/getlist'
                                        syncField='Name'
                                        @syncFieldEvent="syncMiddlewareServer"
                                    ></LookPopup>
								</el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title"><span style="color: red">*</span> 业务ID：</el-col>
							<el-col :span="9">
								<el-form-item prop="BusinessKey">
                                <el-input v-model="dataSource.BusinessKey"></el-input>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title" ><span style="color: red">*</span> 业务编码：</el-col>
							<el-col :span="9">
								<el-form-item prop="BusinessCode">
                                    <el-input v-model="dataSource.BusinessCode">
                                    </el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title"> 业务名称：</el-col>
							<el-col :span="9">
								<el-form-item prop="BusinessName">
                                <el-input v-model="dataSource.BusinessName"></el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title"> 数据库名：</el-col>
							<el-col :span="9">
								<el-form-item prop="DbName">
                                    <el-input v-model="dataSource.DbName" :maxlength="64" :minlength="2" :placeholder="数据库名">
                                    </el-input>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title" > 用户名：</el-col>
							<el-col :span="9">
								<el-form-item prop="UserName">
                                    <el-input v-model="dataSource.UserName" :maxlength="64" :minlength="2" :placeholder="用户名">
                                    </el-input>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title"> 密码：</el-col>
							<el-col :span="9">
								<el-form-item prop="UserPwd">
                                <el-input v-model="dataSource.UserPwd" :maxlength="64" :minlength="2" :placeholder="密码" show-password></el-input>
                                </el-form-item>
							</el-col>
                            <el-col :span="3" class="form-title" > MQ管理端口：</el-col>
							<el-col :span="9">
								<el-form-item prop="MQManagerPort">
                                <el-input-number v-model="dataSource.MQManagerPort" :min="-1" :max="65535" :controls=false
                                    :placeholder="不输入使用默认值"></el-input-number>
                                </el-form-item>
							</el-col>
						</el-form-item>

                        <el-form-item>
							<el-col :span="3" class="form-title" > 生效日期：</el-col>
							<el-col :span="9">
								<el-form-item prop="EffectiveStartDate">
                                    <el-date-picker v-model.trim="dataSource.EffectiveStartDate" placeholder="选择日期"
                                        type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
							</el-col>
							<el-col :span="3" class="form-title"> 失效日期：</el-col>
							<el-col :span="9">
								<el-form-item prop="EffectiveEndDate">
                                    <el-date-picker v-model.trim="dataSource.EffectiveEndDate" placeholder="选择日期"
                                        type="date" value-format="yyyy-MM-dd HH:mm:ss">
                                    </el-date-picker>
                                </el-form-item>
							</el-col>
						</el-form-item>
                        
						<el-form-item>
							<el-col :span="3" class="form-title">备注：</el-col>
							<el-col :span="21">
								<el-form-item prop="Remark">
									<el-input type="textarea" v-model="dataSource.Remark" rows="3"></el-input>
								</el-form-item>
							</el-col>
						</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
    export default {
        data() {
            return {
                collapseItem: ['basicinfo','basicinfo1',''],
                baseCheckRule: {
                    TenantSystemName:[{ required: true, message: '不能为空', trigger: 'blur' }],
                    MiddlewareServerName: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    BusinessKey: [{ required: true, message: '不能为空', trigger: 'blur' }],
                    BusinessCode: [{ required: true, message: '不能为空', trigger: 'blur' }],
                },
                activeName:"tabbasic",
                dataSource:{
                },
                processStatusList:[],
            }
        },
        props: {
            config: {
                isDetailDisplay: false,
                isAdd:false
            },
        },
        mounted: function () {
        },
        computed: {
            
        },
        watch: {
            'config.dataSourceKey':{
                handler(curVal, oldVal) {
                    if(curVal&&curVal>0){
                        if(curVal!=oldVal) 
                            this.initial(curVal); //初始化
                    }else{
                        this.add(); //新增初始化
                    }
                },
                deep: true
            }
        },
        methods: {
            //重置表单
            resetForm: function () {
                this.Utils.clearForm(this.dataSource);
            },
            back: function () {
                this.config.isDetailDisplay = false;
            },
            add: function () {
                this.initial();
                this.config.isAdd = true;
            },
            initial(id){
                var _this = this;
                _this.$ajax.send("omsapi/tenantmiddleware/get", "get", { id: id }, (data) => {
                    _this.dataSource=data.Result;
                    _this.$refs["basicForm"].clearValidate();
                });
            },
            save: function () {
                var _this = this;
                _this.$refs["basicForm"].validate((valid) => {
                    if (valid) {
                        _this.$ajax.send(
                            'omsapi/tenantmiddleware/save',
                            'post',
                            _this.dataSource,
                            (data) => {
                                _this.dataSource = data.Result;
                                _this.Event.$emit('reloadPageList', data.Result)
                                _this.Utils.messageBox('保存成功.', 'success')
                            }
                        )
                    } else {
                        return false
                    }
                });
            },
            syncMiddlewareSystem:function(row){
                if(row){
                    this.dataSource.TenantSystemId = row.Id;
                    this.dataSource.BusinessCode = row.TenantCode;
                    this.dataSource.BusinessKey = row.TenantCode;
                    this.dataSource.BusinessName = row.TenantName;
                }
            },
            syncMiddlewareServer:function(row){
                if(row){
                    this.dataSource.MiddlewareServerId = row.Id;
                }
            },
        },
        components: {
        }
    }
</script>